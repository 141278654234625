import * as yup from 'yup';
import type { CustomerAddress, StoreLocator } from '@robustastudio/e-commerce/common';
import type { ShippingMethod } from '@robustastudio/e-commerce/common';
import type { AvailablePaymentMethod } from '@robustastudio/e-commerce/common';
import { debounce } from 'lodash-es';
import { extendIsPhone } from '~/utils/phone';

type ObjectInputType = { id: number; code: string; name: string };
export type FormValuesType = {
  address?: string;
  region?: ObjectInputType;
  city?: string;
  floor?: string;
  landmark?: string;
  street?: string;
  title?: string;
  apartment?: string;
  selectedAddress?: CustomerAddress;
  customerEmail?: string;
  password?: string;
  customerPhone?: string;
  customerFullName?: string;
};
export function useCheckoutShippingValidationSchema() {
  const { t } = useI18n({
    useScope: 'global',
  });

  const mandatoryShippingFields = yup.object({
    deliveryType: yup.mixed<'home-delivery' | 'store-pickup'>().required().default('home-delivery'),
    store: yup.mixed<StoreLocator>().optional(),
    notes: yup.string().optional(),
  });

  const addressValidationSchema = yup.object({
    customerFullName: yup
      .string()
      .required(t('validation.required').toString())
      .test('customerFullName', t('validation.fullName').toString(), function (value: string) {
        return !!value.trim().match(/^([^\s\d]+)\s+(\D+)$/g);
      }),
    customerPhone: yup
      .mixed<string>()
      .required(t('validation.required').toString())
      .test('customerPhone', t('validation.invalidPhone').toString(), function (value) {
        return extendIsPhone(value.toString());
      }),
    region: yup.string().required(t('validation.region').toString()).default(''),
    city: yup.string().required(t('validation.city').toString()).default(''),
    street: yup.string().required(t('validation.street').toString()).default(''),
    floor: yup.string().optional(),
    apartment: yup.string().optional(),
    landmark: yup.string().optional(),
    title: yup.string().default(''),
    deliveryType: yup.mixed<'home-delivery' | 'store-pickup'>().required().default('home-delivery'),
    store: yup.mixed<StoreLocator>().optional(),
    notes: yup.string().optional(),
  });

  const createCustomerValidationSchema = yup
    .object({
      customerFullName: yup
        .string()
        .required(t('validation.required').toString())
        .test('customerFullName', t('validation.fullName').toString(), function (value: string) {
          return !!value.trim().match(/^([^\s\d]+)\s+(\D+)$/g);
        }),
      customerEmail: yup.string().email(t('validation.email')).required(t('validation.required').toString()),
      customerPhone: yup
        .mixed<string>()
        .required(t('validation.required').toString())
        .test('customerPhone', t('validation.invalidPhone').toString(), function (value) {
          return extendIsPhone(value.toString());
        }),
      password: yup
        .string()
        .required(t('validation.required'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, and One Special Case Character',
        ),
    })
    .concat(addressValidationSchema)
    .concat(mandatoryShippingFields);

  const selectedAddressValidationSchema = yup
    .object({
      selectedAddress: yup.mixed<CustomerAddress>().required(t('validation.selectAddress')),
    })
    .concat(mandatoryShippingFields);

  return {
    addressValidationSchema,
    createCustomerValidationSchema,
    selectedAddressValidationSchema,
  };
}

export function useSetAutoShippingMethod() {
  const { setShippingMethod, isFetching } = useShippingMethods();
  const { value: selectedShippingMethod } = useField<ShippingMethod>('shippingMethod');

  watch(selectedShippingMethod, shippingMethod => {
    if (shippingMethod) {
      setShippingMethod({
        carrier_code: shippingMethod.carrier_code,
        method_code: shippingMethod.method_code || '',
      });
    }
  });

  return {
    isSettingShippingMethod: computed(() => isFetching.value),
  };
}

export function useSetAutoPaymentMethod() {
  const { setPaymentMethod, isFetching } = usePaymentMethods();
  const { value: selectedPaymentMethod } = useField<AvailablePaymentMethod>('paymentMethod');

  watch(
    selectedPaymentMethod,
    debounce(paymentMethod => {
      if (paymentMethod) {
        setPaymentMethod(paymentMethod.code);
      }
    }, 200),
  );

  return {
    isSettingPaymentMethod: computed(() => isFetching.value),
  };
}

export function useGuestCheckoutShippingValidationSchema() {
  const { t } = useI18n({
    useScope: 'global',
  });

  const addressValidationSchema = toTypedSchema(
    yup.object({
      customerFullName: yup
        .string()
        .required(t('validation.required').toString())
        .test('fullName', t('validation.fullName').toString(), function (value: string) {
          return !!value.trim().match(/^([^\s\d]+)\s+(\D+)$/g);
        }),

      customerPhone: yup
        .string()
        .required(t('validation.required').toString())
        .test('phone', t('validation.invalidPhone').toString(), function (value) {
          return extendIsPhone(value.toString());
        }),
      city: yup.string().required(t('validation.required')),
      region: yup.string().required(t('validation.required')),
      street: yup.string().required(t('validation.street').toString()),
      floor: yup.string().optional(),
      apartment: yup.string().optional(),
      landmark: yup.string().optional(),
      title: yup.string().optional(),
      email: yup.string().email(t('validation.email')).required(t('validation.required').toString()),
    }),
  );

  return { addressValidationSchema };
}

export function useAutoFillingAddress() {
  const {
    value: customerFullName,
    meta: customerFullNameMeta,
    validate: validateFullName,
  } = useField('customerFullName');

  const {
    value: customerPhone,
    meta: customerPhoneMeta,
    validate: validatePhoneNumber,
  } = useField<string>('customerPhone');

  const { setValue: setFullName } = useField('fullName');
  const { setValue: setPhoneNumber } = useField('phone');

  watch(customerPhone, async newCustomerPhone => {
    await validatePhoneNumber();
    if (customerPhoneMeta.valid) {
      setPhoneNumber(newCustomerPhone);
    }
  });

  watch(customerFullName, async newCustomerFullName => {
    await validateFullName();
    if (customerFullNameMeta.valid) {
      setFullName(newCustomerFullName);
    }
  });
}
/**
 * Used to auto select address when there is only one address, or auto set the default address
 */
export function useAutoSelectAddress(addresses: ComputedRef<CustomerAddress[]>) {
  const { value } = useField('selectedAddress');

  watch(addresses, newAddresses => {
    if (newAddresses.length === 1) {
      value.value = newAddresses[0];
    }
  });
}
